import { Inject, EventEmitter, Injectable, NgZone, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Location } from '@angular/common';;
import { Moment } from "moment";
import moment from "moment";
import { config, isProductionServer, rendersInBrowser } from "../environments/environment";
import { Router, ActivatedRoute, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, NavigationStart, Params } from '@angular/router';
import { BackendRestService } from "./Restangular.service";
import { Restangular } from "ngx-restangular";
import { ShowNavService } from "./ShowNav.service";
// import { FullHeightPageService } from "./FullHeight.service";
import { Destroyable } from "../directives/destroyable.directive";
import { filter, map, first } from "rxjs/operators";
import { LoginService } from "./login.service";
import { GoogleMap, MapBaseLayer } from "@angular/google-maps";
import { ProtoBufService } from "./protobuf.service";
import { matchesProperty } from "lodash";
import numeral from "numeral";
import { FirstLetterPipe } from "../pipes/FormatInfostars.pipe";
import { CanvasToPngService } from "./canvas-to-png.service";
import { Observable, forkJoin } from "rxjs";

export const ZONE_STROKE_COLOUR = '#0000FF';
export const ZONE_FILL_COLOUR = '#008000';
export class ScreenInfo {
	isScreenSmall: boolean;
	isScreenMedium: boolean;
	isScreenLarge: boolean;
	isScreenXLarge: boolean;
	isScreenXXLarge: boolean;
	screenSize: 'small'|'medium'|'large'|'xlarge'|'xxlarge';
	screenWidth: number;
	screenHeight: number;
}
/** Helper methods which do not deserve a service of their own */
@Injectable()
export class InfostarsToolsService extends Destroyable {
	public activeLang:string = config.determineLanguage();
	public otherLangURLs:any = {};

	/** Screen size. WARNING: isScreenLarge and isScreenMedium are active at the same time on large screens */
	public isScreenSmall = false;
	public isScreenMedium = false;
	public isScreenLarge = false;
	public isScreenXLarge = false;
	public isScreenXXLarge = false;
	public screenSize:'small'|'medium'|'large'|'xlarge'|'xxlarge' = 'small';
	public screenWidth = rendersInBrowser() ? $(window).width() : 1920;
	public screenHeight = rendersInBrowser() ? $(window).height() : 1080;
	public isMobile = false;
	public isPrerenderProxy = rendersInBrowser() ? (navigator.userAgent||navigator.vendor||(window as any).opera).match(/.*Prerender.*/) : false;
	public agbModalId = 'agbModal';
	public hotkeysModalId = 'hotkeysModal';
	public privacyPolModalId = 'privacyModal';
	public defaultShowSexMaleOnly: boolean;

	public activeLang$ = new EventEmitter<string>();
	public otherLangURLs$ = new EventEmitter<string>();
	/** Emitted when any of the [is]screen... properties change */
	public screen$ = new EventEmitter<ScreenInfo>();
	public dialogOpened$ = new EventEmitter<String>();

	private redirectPath:string;
	private forceDisplayMain: boolean;
	private currRoutePath: string;
	private prevRoutePath: string;
	private routeReuseStrategy:any = null;
	private okSaveScroll = false;
	private scrollPos: any = {}; // scroll position of each view
	private useCustomViewContentLoadedFunc: boolean;
	private foundationInitObj = {
		abide: {
			live_validate : true,
			// Passing this to the init func does not work yet due to https://github.com/zurb/foundation/issues/4177
			// We're using a hack and modify Foundation.libs.abide.settings, see below
			patterns: {
				...config.validatorPatterns
			}
		}
//		reveal: {
//			close_on_esc: false,
//			close_on_background_click: false,
//		}
	}
	private jQuery_class2type: any = {};
	constructor(
		@Inject(BackendRestService) private BackendRest:Restangular,
		@Inject(ShowNavService) public ShowNav: ShowNavService,
		// @Inject(FullHeightPageService) public FullHeightPage: FullHeightPageService,
		@Inject(TranslateService) public $translate: TranslateService,
		@Inject(FirstLetterPipe) public FirstLetterPipe: FirstLetterPipe,
		@Inject(ProtoBufService) protected ProtoBuf: ProtoBufService,
		@Inject(Router) public $router: Router,
		@Inject(ActivatedRoute) private $route: ActivatedRoute,
		@Inject(Location) public $location: Location,
		private ngZone: NgZone
	) {
		super();
		// Populate the class2type map for our fake jQuery.extends (see below)
		"Boolean Number String Function Array Date RegExp Object Error".split(" ").forEach(name => {
			this.jQuery_class2type[ "[object " + name + "]" ] = name.toLowerCase();
		});
		$translate.use(this.activeLang); // Set the translation language
		moment.locale(this.activeLang); // Set the language for displaying date/time
		numeral.locale(this.langToNumeralLocale(this.activeLang)); // Set the language for displaying money
		// Listen to state transitions after successful completion
		this.subscribe(this.$router.events.pipe(filter(event => event instanceof NavigationEnd)), (ev: NavigationEnd) => {
			setTimeout(() => { // Wait for the page component ngOnInit to be called
				if(!this.useCustomViewContentLoadedFunc)
					this.doViewContentLoaded();
				this.useCustomViewContentLoadedFunc = false;
			})
			let rootState = this.$router.routerState.snapshot.root;
			let newLang = rootState.firstChild && rootState.firstChild.params['lang'];
			if(newLang !== undefined) {
				if(!config.supportedLangMap[newLang])
					newLang = config.determineLanguage(); // Override invalid languages to make sure redirects work properly
				let prevLang = this.activeLang;
				this.activeLang = newLang;
				// Update this.otherLangURLs for translation links (country flags)
				let newOtherLangURLs:any = {};
				Object.keys(config.supportedLangMap).forEach((lang:string) => {
					if(lang !== this.activeLang) {
						newOtherLangURLs[lang] = $location.prepareExternalUrl($location.path().replace('/' + this.activeLang, '/' + lang));
					}else {
						newOtherLangURLs[lang] = null;
					}
				});
				newOtherLangURLs['default'] = $location.prepareExternalUrl($location.path().replace('/' + this.activeLang, '/' + config.fallbackLang));
				this.otherLangURLs = newOtherLangURLs;
				this.otherLangURLs$.emit(this.otherLangURLs);
				if(newLang !== prevLang) {
					$translate.use(this.activeLang); // Switch the translation language
					moment.locale(this.activeLang); // Switch the language for displaying date/time
					numeral.locale(this.langToNumeralLocale(this.activeLang)); // Switch the language for displaying money
					this.activeLang$.emit(this.activeLang);
				}
			}
			if(this.routeReuseStrategy !== null) {
				this.$router.routeReuseStrategy.shouldReuseRoute = this.routeReuseStrategy;
				this.routeReuseStrategy = null;
			}
			// In angular router, there's no event to capture the curr URL when a navigation happens, so we store the curr when a navigation finished to calculat the previous URL
			this.prevRoutePath = this.currRoutePath;
			this.currRoutePath = ev.url;
		});
		// Listen to state transitions before they occur
		this.subscribe(this.$router.events.pipe(filter(event => event instanceof NavigationStart)), (ev: NavigationStart) => {
			this.okSaveScroll = false;
		});
		if(rendersInBrowser()) {
			$(window).on('scroll', () => {
				if (this.okSaveScroll) { // false between $transitions.onBefore and $viewContentLoaded
//					console.log('Saving scroll post to: ', $(window).scrollTop());
					this.scrollPos[$location.path()] = $(window).scrollTop();
				}
			});
		}
		// XXX Is this the right spot for this call?
		this.handleWindowResized();
		// Determine if this is a mobile device
		((a) => {
			if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
				this.isMobile = true;
		})(rendersInBrowser() ? (navigator.userAgent||navigator.vendor||(window as any).opera) : '');
	}

	ngOnDestroy() { super.ngOnDestroy(); }

	private langToNumeralLocale(lang:string) {
		return lang === 'da' ? 'da-dk' : lang;
	}

	private jQuery_type( obj:any ) {
		if ( obj == null ) {
				return obj + "";
		}
		// Support: Android<4.0, iOS<6 (functionish RegExp)
		return typeof obj === "object" || typeof obj === "function" ?
				this.jQuery_class2type[ toString.call(obj) ] || "object" :
				typeof obj;
	}
	private jQuery_isFunction( obj:any ) {
		return this.jQuery_type(obj) === "function";
	}
	private jQuery_isPlainObject( obj:any ) {
		// Not plain objects:
		// - Any object or value whose internal [[Class]] property is not "[object Object]"
		// - DOM nodes
		// - window
		if ( this.jQuery_type( obj ) !== "object" || obj.nodeType || this.jQuery_isWindow( obj ) ) {
				return false;
		}

		if ( obj.constructor &&
						// !hasOwn.call( obj.constructor.prototype, "isPrototypeOf" ) ) {
						!obj.constructor.prototype.hasOwnProperty("isPrototypeOf") ) {
				return false;
		}

		// If the function hasn't returned already, we're confident that
		// |obj| is a plain object, created by {} or constructed with new Object
		return true;
	}
	private jQuery_isArray( arg:any ) {
		return Array.isArray(arg);
	}
	private jQuery_isWindow( obj:any ) {
		return obj != null && obj === obj.window;
	}
	/** jQuery extend replacement, as we can't rely on jQuery for server side rendering and require the deep copy version. Adapted from jQuery 2.1.4 src/core.js */
	public jQuery_extend(...args: any[]) {
		var options, name, src, copy, copyIsArray, clone,
			target = arguments[0] || {},
			i = 1,
			length = arguments.length,
			deep = false;

		// Handle a deep copy situation
		if ( typeof target === "boolean" ) {
			deep = target;

			// Skip the boolean and the target
			target = arguments[ i ] || {};
			i++;
		}

		// Handle case when target is a string or something (possible in deep copy)
		if ( typeof target !== "object" && !this.jQuery_isFunction(target) ) {
			target = {};
		}

		// Extend jQuery itself if only one argument is passed
		if ( i === length ) {
			target = this;
			i--;
		}

		for ( ; i < length; i++ ) {
			// Only deal with non-null/undefined values
			if ( (options = arguments[ i ]) != null ) {
				// Extend the base object
				for ( name in options ) {
					src = target[ name ];
					copy = options[ name ];

					// Prevent never-ending loop
					if ( target === copy ) {
						continue;
					}

					// Recurse if we're merging plain objects or arrays
					if ( deep && copy && ( this.jQuery_isPlainObject(copy) || (copyIsArray = this.jQuery_isArray(copy)) ) ) {
						if ( copyIsArray ) {
							copyIsArray = false;
							clone = src && this.jQuery_isArray(src) ? src : [];

						} else {
							clone = src && this.jQuery_isPlainObject(src) ? src : {};
						}

						// Never move original objects, clone them
						target[ name ] = this.jQuery_extend( deep, clone, copy );

					// Don't bring in undefined values
					} else if ( copy !== undefined ) {
						target[ name ] = copy;
					}
				}
			}
		}

		// Return the modified object
		return target;
	}
	public jQuery_Foundation_reveal(sel:string, action:string) {
		if(!rendersInBrowser())
			return;
		($(sel) as any).foundation('reveal', action);
	}
	/** Transforms an array of strings into a list of {name: translated_xxx, value: xxx} objects to be used for a select tag */
	public optionsToScopeArray(translPrefix:string, options:any, translParams?:any) {
		let scopeArr:any = [];
		options.forEach((o:any) => {
			scopeArr.push({name: this.$translate.instant(translPrefix + o, translParams), value: o});
		});
		return scopeArr;
	}
	/** Transforms a list of objects with nameId properties (translations) to an array to be used for a select tag */
	public optionsToAssociationArray(translPrefix:string, options:any, subProperty?:string) {
		var scopeArr:any = [];
		options.forEach((o:any) => {
			var nameId = subProperty ? o[subProperty].nameId : o.nameId;
			o.name = this.$translate.instant(translPrefix + nameId);
			scopeArr.push(o);
		});
		return scopeArr;
	}
	/** Transform an array used for a select tag to a map based on the 'id' (or custom) property */
	public associationArrayToMap(assoc:any[], prop?:string) {
		return (assoc || []).reduce((map, obj) => {
			map[prop ? obj[prop] : obj.id] = obj;
			return map;
		}, {});
	}
	/** Set the checked = true/false attribute on every object in entityList, depending
	 * on whether there is an entry with the same 'id' attribute in listOfChecked.
	 * Useful when populating the options for multi select lists.
	 * Returns if all from listOfChecked were found */
	public setChecked(entityList:any[], listOfChecked:any, prop?:string) {
		if(!entityList || entityList.length === 0)
			return !listOfChecked || listOfChecked.length === 0;
		prop = prop || 'id';
		let idToEntity:any = {};
		(entityList || []).forEach((e) => {
			e.checked = false;
			idToEntity[e[prop]] = e;
		});
		if(!listOfChecked || listOfChecked.length === 0)
			return true;
		let allFound = true;
		(listOfChecked || []).forEach((c:any) => {
			const id = c[prop] || c;
			if(idToEntity[id])
				idToEntity[id].checked = true;
			else
				allFound = false;
		});
		return allFound;
	}
	/** Transform a list of entities with 'checked' attributes into a list of values
	 * using the 'id' attribute of checked entities. */
	public selectionToIds(selection:any[]) {
		let idOnly:any[] = [];
		(selection || []).forEach((sel) => {
			if(sel.checked)
				idOnly.push(sel.id);
		});
		return idOnly;
	}
	/** Transform a list of entities with 'checked' attributes into a list of shallow objects
	* with only the 'id' attribute of checked entities. */
	public selectionToIdOnly(selection:any[]) {
		var idOnly:any[] = [];
		if (selection === null || selection === undefined) return idOnly;
		(selection || []).forEach((sel) => {
			if(sel.checked)
				idOnly.push({id: sel.id});
		});
		return idOnly;
	}
	/** Find the entry in entityList which matches currValue, either by the 'id' attribute or by value.
	 * This is useful to populate the model value of a select tag, after loading all options from the backend */
	public findSelected(entityList:Array<any>, currValue:any, idProp?:string): any {
		var res = null;
		if(!currValue || !entityList)
			return;
		idProp = idProp || 'id';
		entityList.some((e) => {
			if(e[idProp] === (currValue[idProp] || currValue)) {
				res = e;
				return true;
			}
			return false;
		});
		return res;
	}
	public combineDateAndTime(dateMoment:Moment, timeMoment:any) {
		if(typeof timeMoment === 'string')
			return moment(dateMoment.format('YYYY-MM-DD') + ' ' + timeMoment + ':00', 'YYYY-MM-DD HH:mm:ss');
		return moment(dateMoment.format('YYYY-MM-DD') + ' ' + timeMoment.format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
	}
	public timeStrToHHmm(timeStr:string) {
		return moment('2015-10-20 ' + timeStr).format('HH:mm');
	}
	public calcStartTime(baseMoment:Moment, fromTime:string):Moment {
		return moment(baseMoment.format('YYYY-MM-DD') + ' ' + fromTime, 'YYYY-MM-DD HH:mm:ss');
	}
	public calcEndTime(baseMoment:Moment, startMoment:Moment, toTime:string):Moment {
		var $aEnd = moment(baseMoment.format('YYYY-MM-DD') + ' ' + toTime, 'YYYY-MM-DD HH:mm:ss');
		if($aEnd.isBefore(startMoment)) { // Use case: Fr 19:00 - 02:00
			$aEnd.add(1, 'days');
		}
		return $aEnd;
	}
	public phoneNumberToCountryAndNumber(phoneNumber:string, chkPhoneNumberCountryOptions:Array<any>) {
		var phoneNumberCountry = (phoneNumber && phoneNumber.length > 3) ? phoneNumber.substring(1, 3) : '';
		if(phoneNumberCountry && phoneNumberCountry[0] === '1')
			phoneNumberCountry = '1';
		var chkCountry = null as any;
		chkPhoneNumberCountryOptions.some((o) => {
			if(o.value === phoneNumberCountry) {
				chkCountry = o;
				return true;
			}
			return false;
		});
		phoneNumber = (phoneNumber && phoneNumber.length > 3) ? (phoneNumberCountry === '1' ? phoneNumber.substring(2) : phoneNumber.substring(3)) : '';
		return {country: chkCountry, number: phoneNumber};
	}
	/** Process the raw TourStatus enum and modify it with custom names (TourStatusCustom)
	 * If there are no customTourStati, then remove the custom enum entries from tourStatusTypes
	 */
	public getTourStatusOptions(tourStatusTypes:any[], customTourStati:any[]) {
		let selTourStatus = this.optionsToScopeArray('ENUM_TourStatus_', tourStatusTypes);
		if(customTourStati) { // If there are any custom tour stati, replace the title of the select options with that custom title and only show the renamed custom stati
			let tsToTsCustom = customTourStati.reduce((map:any,tsCustom:any) => { map[tsCustom.tourStatus] = tsCustom; return map },{});
			selTourStatus = selTourStatus.reduce((res:any[], tsc:any) => {//).map((tsc:any) => {
				if(tsToTsCustom[tsc.value] && tsc.value !== 'PRIVATE') { // Prohibit PRIVATE from being overridden
					tsc.name = tsToTsCustom[tsc.value].name;
					tsc.shortName = tsToTsCustom[tsc.value].shortName;
					res.push(tsc);
				}else if(tsc.value === 'BUSINESS' || tsc.value === 'PRIVATE') {
					res.push(tsc);
				}
				return res;
			}, []);
		}else {
			let bizFound = false;
			selTourStatus = selTourStatus.filter((tsc:any) => bizFound ? false : (bizFound = tsc.value === 'BUSINESS', true)); // Remove all custom business tour stati (they come after 'BUSINESS')
		}
		return selTourStatus;
	}
	public getTourStatusOptionsShort(tourStatusTypes:any[], customTourStati:any[]) {
		return this.getTourStatusOptions(tourStatusTypes, customTourStati).map((tsc:any) => {
			if(tsc.shortName)
				tsc.name = tsc.shortName;
			else
				tsc.name = this.FirstLetterPipe.transform(tsc.name);
			return tsc;
		});
	}
	/** Forcefully reload the page (with component reload) */
	public reloadPage() {
		this.routeReuseStrategy = this.$router.routeReuseStrategy.shouldReuseRoute;
		this.$router.routeReuseStrategy.shouldReuseRoute = () => false;
		// Requires onSameUrlNavigation:'reload' option on RouterModule.forRoot(...) call
		this.$router.navigate([], {
			skipLocationChange: true,
			queryParamsHandling: 'merge' //== if you need to keep queryParams
		})
	}
	public getCurrPath(route: ActivatedRoute): string {
		return (route as any)['_routerState'].snapshot.url; // See https://stackoverflow.com/a/53504783/289064
	}
	public setRedirectPath(path:string) {
		this.redirectPath = path;
	}
	public getAndResetRedirectPath() {
		var path = this.redirectPath;
		this.redirectPath = null;
		return path;
	}
	public setForceDisplayMain() {
		this.forceDisplayMain = true;
	}
	public getAndResetForceDisplayMain() {
		var force = this.forceDisplayMain;
		this.forceDisplayMain = false;
		return force;
	}
	/** Return combined data from routes for all nodes leading to the current page in the routing tree */
	public getAllRouteData(route: ActivatedRouteSnapshot): {[name: string]: any} {
		const data = {};
		return this.getAllRouteDataInternal(route, data);
	}
	private getAllRouteDataInternal(route: ActivatedRouteSnapshot, data: {[name: string]: any}): {[name: string]: any} {
		Object.keys(route.data).forEach(k => data[k] = route.data[k]);
		route.children.forEach(c => this.getAllRouteDataInternal(c, data));
		return data;
	}
	/** Get all leaf ActivedRoute from the active routing tree, see https://medium.com/angular-in-depth/angular-routing-series-pillar-1-router-states-and-url-matching-12520e62d0fc */
	public getLeafRoutes(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
		return this.getLeafRoutesInternal(route, []);
	}
	private getLeafRoutesInternal(route: ActivatedRouteSnapshot, leafs: any) {
		if(route.children.length === 0) {
			leafs.push(route);
			return leafs;
		}
		route.children.forEach(cr => this.getLeafRoutesInternal(cr, leafs));
		return leafs;
	}
	public getLeafParams(route: ActivatedRouteSnapshot): Params {
		return this.getLeafRoutes(route).map(lf => lf.params).reduce((prev, curr) => Object.assign(prev, curr), {});
	}
	// Adaped from https://stackoverflow.com/a/57477939
	public generateRoutePath(
		route: ActivatedRoute,
		extras?: { params?: any, preserveMatrix?: boolean, recursive?: boolean, preserveChildMatrix?: boolean, replaceChildParams?: boolean }
	): any[] {
		return this.generateRoutePathInternal(route, extras);
	}
	private generateRoutePathInternal(
		route: ActivatedRoute,
		extras?: { params?: any, parentParams?: any, preserveMatrix?: boolean, recursive?: boolean, preserveChildMatrix?: boolean, replaceChildParams?: boolean }
	): any[] {
		const defaultRouterExtras = {
			params: {},
			parentParams: {}, // Only for internal use!
			preserveMatrix: true,
			recursive: true,
			preserveChildMatrix: false,
			replaceChildParams: true
		}
		const mergedExtras = { ...defaultRouterExtras, ...extras };
		const pathInheritsParams = route.routeConfig.path === '' || !route.routeConfig.component; // Routes inherit parent params in these cases, see https://kimsereyblog.blogspot.com/2018/02/params-inheritance-strategy-with.html
		const paramsToFill = { ...route.snapshot.params, ...mergedExtras.params };
		if(pathInheritsParams && mergedExtras.parentParams) // Don't fill the parent params again
			Object.keys(mergedExtras.parentParams).forEach(k => delete paramsToFill[k]);
		// console.log('Initial path: ' + route.routeConfig.path);
		let path: any[] = route.routeConfig.path !== '' ?
			[...this.fillParamsIntoPath(route.routeConfig.path.split('/'), paramsToFill, mergedExtras.params)] :
			[];
		// console.log('Path after replaceRouteParams: ' + path.join('/'));

		if (mergedExtras.preserveMatrix) {
			const newMatrix = this.replaceMatrixParams(route, mergedExtras.params, mergedExtras.parentParams);
			if(Object.keys(newMatrix).length > 0)
				path.push(newMatrix);
		}
		// console.log('Path after replaceMatrixParams: ' + path.join('/'));
		if (mergedExtras.recursive && route.children.length > 0) {
			// console.log('Recursing with children: ' + route.children[0].routeConfig.path);
			path.push(...this.generateRoutePathInternal(route.children[0], {
				params: mergedExtras.replaceChildParams ? mergedExtras.params : {},
				parentParams: { ...route.snapshot.params },
				preserveMatrix: mergedExtras.preserveChildMatrix,
				recursive: mergedExtras.recursive,
				preserveChildMatrix: mergedExtras.preserveChildMatrix,
				replaceChildParams: mergedExtras.replaceChildParams
			}));
		}

		return path;
	}
	private fillParamsIntoPath(parts: string[], params: any, paramsToConsume: any): any[] {
		return (parts || [])
			.map(part => {
				if(part.startsWith(':') && params.hasOwnProperty(part.substring(1))) {
					delete paramsToConsume[part.substring(1)];
					return params[part.substring(1)]
				}else {
					return part;
				}
			});
	}
	private replaceMatrixParams(route: ActivatedRoute, params: any, parentParams: any): any {
		const matrix: any = {};
		const pathInheritsParams = route.routeConfig.path === '' || !route.routeConfig.component;
		const pathSegments = route.routeConfig.path.split('/');
		Object.keys(route.snapshot.params).forEach(key => {
			if(pathInheritsParams && parentParams && parentParams.hasOwnProperty(key))
				return; // Don't fill parent parameters again, if the route inherits them
			let existingValue = route.snapshot.params[key];
			if(!pathSegments.some(s => s === (':' + key)))
				matrix[key] = params.hasOwnProperty(key) ? params[key] : existingValue;
		});
		return matrix;
	}
	public getPrevRoutePath() {
		return this.prevRoutePath;
	}
	/** Useful to wait for data which influences page layout */
	public disableStoreScrollPosition() {
		this.okSaveScroll = false;
	}
	/** Useful to wait for data which influences page layout */
	public enableStoreScrollPosition() {
		this.okSaveScroll = true;
	}
	public restoreScrollPosition() {
		// Scroll to the retained scroll position
		$(window).scrollTop(this.scrollPos[this.$location.path()] ? this.scrollPos[this.$location.path()] : 0);
		this.okSaveScroll = true;
	}
	public refreshFormValidation($form:any) {
		setTimeout(() => {
			// Make sure abide validation is notified of new fields
			$form.foundation({bindings: 'events', ...this.foundationInitObj });
		}, 0);
	}
	private isProductionServer() {
		return isProductionServer();
	}
	private trackPixel(url:string) {
		var $image = $(new Image());
		// Handle the image loading and error with the same callback.
		$image.on('load.trackingPixel error.trackingPixel', () => {
			$image.off('load.trackingPixel error.trackingPixel');
		});
		$image.attr('src', url);
	}
	// See https://developers.google.com/analytics/devguides/collection/analyticsjs/events
	public trackEvent(category:string, action?:string, label?:string, value?:any) {
		if(this.isProductionServer()) {
			// if(category !== 'view_profile') // view_profile is just a workaround for our campaign tracking (no page tracking)
			// 	this.Analytics.event(category, action, label, value);
		}
	}
	public trackPurchase(booking:any) {
		// this.Analytics.trackPurchase(booking);
	}
	public hackImageUrls(imgTagSelector:string) {
		// XXX Disabled for webpack, doesn't seem to be required anymore
		// Hack around mobile proxy servers, which don't expect us to use html as templates and embed it into a html with a different URL (index.html vs views/main.html)
		// $(imgTagSelector).each(function() {
		// 	var $img = $(this);
		// 	var imgUrl = $img.attr('src');
		// 	if(imgUrl)
		// 		$img.attr('src', 'assets/images/' + imgUrl.substr(imgUrl.indexOf('/')+1));
		// });
	}
	// Returns a five character pseuso-random string with a-z
	public randomString() {
		return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
	}
	private initBasemap(scope:any, map:GoogleMap) {
		if(map.mapTypes.get('basemap'))
			return; // Prevent double-inits
		let baseMapType = new google.maps.ImageMapType({
			getTileUrl: (coord, zoom) => {
				let numTiles:number = 1 << zoom,
					wx = coord.x % numTiles,
					x = (wx < 0) ? wx + numTiles : wx,
					y = coord.y,
					index = (zoom + x + y) % 4,
					project = function(latLng:google.maps.LatLngLiteral) {
						var siny = Math.sin(latLng.lat * Math.PI / 180);
						return {
							x: Math.floor((0.5 + latLng.lng / 360) * numTiles),
							y: Math.floor((0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)) * numTiles)
						};
					},
					// EPSG:31297 Austria Lambert
					allowedBounds = [{
						lat: 46.41,
						lng: 9.53
					}, {
						lat: 49.02,
						lng: 17.17
					}],
					sw = project(allowedBounds[0]),
					ne = project(allowedBounds[1]);

				if (x > ne.x || y < ne.y || x < sw.x || y > sw.y) return null;

				return '//mapsneu.wien.gv.at/basemap/{N}/normal/google3857/{Z}/{Y}/{X}.{E}'
					.replace('{N}', 'bmaporthofoto30cm')
					.replace('{Z}', '' + zoom)
					.replace('{X}', '' + x)
					.replace('{Y}', '' + y)
					.replace('{E}', 'jpg');
			},
			tileSize: new google.maps.Size(256, 256),
			name: 'Austria Sat',
			alt: 'basemap.at',
			minZoom: 3,
			maxZoom: 19
		});
		map.mapTypes.set('basemap', baseMapType); // Register the map type
		// Show the basemap as an option in the map type control
		scope.map.options = {...scope.map.options, mapTypeControlOptions: {
			mapTypeIds: [
				google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, 'basemap'
			]
		} as google.maps.MapTypeControlOptions};
	}
	private getSelectedMapTypeId(searchFilter:any):string|google.maps.MapTypeId {
		var typeId:string = (searchFilter || {}).mapType || 'ROADMAP';
		return (typeId === 'basemap') ? typeId : (google.maps.MapTypeId as any)[typeId];
	}
	public initializeMap(mapApiLoaded$:Observable<boolean>, afterViewInitPromise:Promise<void>, Login:LoginService, scope:any, initMapType:string, initCenter:any, initZoom:number, gMapGetter:() => GoogleMap, onComplete?:Function) {
		Login.getLoginPromise().then(() => {
			let hasBaseMap = Login.hasAnyRight('USE_MAP_BASEMAP');
			let initMapTypeIsBasemap = hasBaseMap && (initMapType || '').toLowerCase() === 'basemap'; // Only allow setting basemap as the initial map type if the permission is granted
			initMapType = ((initMapTypeIsBasemap ? null : initMapType) || 'roadmap').toLowerCase(); // roadmap is the default / fallback
			let tilesloaded = false;
			scope.showBaseMapInfo = false;
			var map = {
				events: {
					tilesloaded: (map:GoogleMap) => {
						if(tilesloaded)
							return;
						this.doViewContentLoaded();
						google.maps.event.trigger(map, 'resize');
						tilesloaded = true;

					},
					maptypeid_changed: (currMap:GoogleMap) => {
						scope.showBaseMapInfo = currMap.getMapTypeId() === 'basemap';
					}
				},
				options: {
					mapTypeId: initMapType,
					center: initCenter,
					zoom: initZoom,
					streetViewControl: false, // Disable the street view button
					scaleControl: true, // Enable the scale for visual measurements
					maxZoom: 19,
				} as google.maps.MapOptions,
				// XXXAngularIO Enabling the clusterer causes lots of google.maps.event.addDomListener() is deprecated, use the standard addEventListener() method instead
				clusterOptions: { // see http://angular-ui.github.io/angular-google-maps/#!/api/markers
					maxZoom: 14, // 0 - 19 (full zoomed in)
					minimumClusterSize: 3,
					imagePath: 'assets/images/markerclustererplus/m'
				},
				infoWindow: {
					show: false,
						coords: {},
					options: { disableAutoPan: false }
				},
				show: true
			};
			scope.map = map;
			if (onComplete)
				onComplete(map);
			if(!hasBaseMap)
				return;
			forkJoin([mapApiLoaded$, afterViewInitPromise]).subscribe(() => {
				setTimeout(() => { // Wait a little until the google maps component is available
					let gMap = gMapGetter();
					if(!gMap) {
						console.error('Google Maps angular component is not yet loaded, make sure you have the angular google maps tag in the DOM latest after mapApiLoaded$ is done.');
						return;
					}
					if(hasBaseMap) {
						this.initBasemap(scope, gMap);
						if(initMapTypeIsBasemap) {
							map.options.mapTypeId = 'basemap';
							// gMap.mapTypeId = map.options.mapTypeId;
						}
					}
				}, 100);
			})
		});
	}

	/** An update function to be installed on a $rootScope.searchFilter watch. Invocation assumes that
	 * uiGmapGoogleMapApi.then has been called, so google.maps is available */
	public getUpdateMapObjFunc(mapObj:any) {
		return (newSearchFilter:any, gMap:GoogleMap) => {
			var typeId = this.getSelectedMapTypeId(newSearchFilter);
			if(gMap && gMap.googleMap)
				gMap.googleMap.setMapTypeId(typeId);
		};
	}
	public boundsToMapBoundsObj(bounds:any) {
		var northEast = bounds.getNorthEast(), southWest = bounds.getSouthWest();
		return {
			northeast: { latitude: northEast.lat(), longitude: northEast.lng() },
			southwest: { latitude: southWest.lat(), longitude: southWest.lng() },
		};
	}
	public mapFocusPosition(map:any, gMap:GoogleMap, latLng:google.maps.LatLng|google.maps.LatLngLiteral, noZoom?:boolean) {
		if(noZoom) {
			let latLngObj = new google.maps.LatLng(latLng); // might be a literal
			map.options = {...map.options, center: latLngObj.toJSON()};
			return;
		}
		let bounds = new google.maps.LatLngBounds().extend(latLng);
		bounds.extend(new google.maps.LatLng(bounds.getNorthEast().lat() + 0.001, bounds.getNorthEast().lng() + 0.001));
		bounds.extend(new google.maps.LatLng(bounds.getSouthWest().lat() - 0.001, bounds.getSouthWest().lng() - 0.001));
		map.bounds = this.boundsToMapBoundsObj(bounds);
		if(noZoom) {
			gMap.fitBounds(bounds);
			map.options.center = gMap.getCenter().toJSON();
			map.options.zoom = gMap.getZoom();
		} else {
			this.mapSetMapCenter(map, gMap, bounds);
		}
	}
	// Change the map center and zoom using bounds
	public mapSetMapCenter(map:any, gMap:GoogleMap, bounds:google.maps.LatLngBounds) {
		if(!gMap)
			return; // We might have to wait for it to become ready
		// Change the map center and zoom using bounds
		gMap.fitBounds(bounds);
		if(gMap.getZoom() > 16)
			gMap.googleMap.setZoom(16);//this.initZoom
		map.options.center = gMap.getCenter().toJSON();
		map.options.zoom = gMap.getZoom();
	}
	/** Add a .icon property with the png data URI or a URL of the image icons (replace CGI script based number icon with canvas rendered ones) */
	public vehicleTreeAddPngIcons(vehicleTree:any[], cvToPng: CanvasToPngService) {
		let procNode = (node:any) => {
			if(node.pictureURL && !node.icon) {
				// Render vehicle numbers on the client (replace the server side iconUrl with a client side png data URL)
				const numPic = cvToPng.vehiclePicToPngUrl(node.picture);
				node.icon = numPic ? numPic.img : node.pictureURL;
			}
			(node.childGroups || []).forEach(procNode);
			(node.vehicles || []).forEach(procNode);
		}
		vehicleTree.forEach(procNode);
	}
	public openVehicleTree(vehicleTree:any[]) {
		let openNode = (node:any) => {
			node.opened = true;
			(node.childGroups || []).forEach(openNode);
		}
		vehicleTree.forEach(openNode);
	}
	public openDriverTree(driverTree:any[]) {
		let openNode = (node:any) => {
			node.opened = true;
			(node.groups || []).forEach(openNode);
		}
		driverTree.forEach(openNode);
	}
	private doActionModal(modalId: string, action: string) {
		($('#' + modalId) as any).foundation('reveal', action);
	}
	public openAgbModal() {
		this.doActionModal(this.agbModalId, 'open');
	}
	public closeAgbModal() {
		this.doActionModal(this.agbModalId, 'close');
	}
	public openHotkeysModal() {
		this.doActionModal(this.hotkeysModalId, 'open');
	}
	public closeHotkeysModal() {
		this.doActionModal(this.hotkeysModalId, 'close');
	}
	public openPrivacyPolModal() {
		this.doActionModal(this.privacyPolModalId, 'open');
	}
	public closePrivacyPolModal() {
		this.doActionModal(this.privacyPolModalId, 'close');
	}
	public collectGpsEqExpireSoon(geInfos:any[]): any[] {
		// Collect all gps equipments which expire soon
		let expireThres = moment().add(4, 'weeks').toDate();
		let gpsEqExpireSoon: any[] = [];
		(geInfos || []).forEach((ge:any) => {
			if(ge && ge.latestWebshopPackageExpiration && ge.latestWebshopPackageExpiration < expireThres)
				gpsEqExpireSoon.push(ge);
		});
		return gpsEqExpireSoon;
	}
	/** See TimeUtils.calculateTimestamps. This does something equivalent, but simpler for the
	 * actual use-case only: Getting a date range to query logbook level2, given a date range of level3
	 * See also LogbookDateRangeTest */
	public clampStartStopDate(start:Moment, end:Moment) {
		if(end.format('YYYY-MM-DD') === end.format('YYYY-MM-DD'))
			return {start: moment(end.format('YYYY-MM-DD')).toDate(), end: end.toDate() };
		return {start: start.toDate(), end: moment(start.format('YYYY-MM-DD') + ' 23:59:59.999').toDate() };
	}
	/** Expandi start/end to the beginning/end of full days make sure they are within the bounds of filterStart/End */
	public expandToFullDaysWithinFilter(start:Moment|number, end:Moment|number, filterStart:Moment, filterEnd:Moment) {
		var res = {
			start: filterStart,
			end: filterEnd,
		};
		// Clip start/stop to searchFilter.start/stop bounds
		var startFullD = moment(start).startOf('day');
		var endFullD = moment(end).endOf('day');
		if(startFullD.isAfter(filterStart))
			res.start = startFullD; // Nice start is within filter bounds, use it
		if(endFullD.isAfter(filterStart) && endFullD.isBefore(filterEnd))
			res.end = endFullD // Nice end is within filter bounds, use it
		return res;
	}
	/** Change start/end to the beginning/end of one single day (from start) and make sure they are within the bounds of filterStart/End */
	public startEndToOneFullDayWithinFilter(start:Moment|number, end:Moment|number, filterStart:Moment, filterEnd:Moment) {
		var res = {
			start: filterStart,
			end: filterEnd,
		};
		// Clip start/stop to searchFilter.start/stop bounds
		var startFullD = moment(start).startOf('day');
		var endFullD = moment(end).endOf('day');
		if(startFullD.isAfter(filterStart))
			res.start = startFullD; // Nice start is within filter bounds, use it
		if(endFullD.isAfter(filterStart) && endFullD.isBefore(filterEnd))
			res.end = endFullD // Nice end is within filter bounds, use it
		// Make sure start/end are on the same day, set stop to 24:00 of start day if not
		var endFullDOnStartDay = moment(start).endOf('day');
		if((endFullD.isAfter(endFullDOnStartDay) || !endFullD.isValid()) && endFullDOnStartDay.isAfter(filterStart) && endFullDOnStartDay.isBefore(filterEnd))
			res.end = endFullDOnStartDay;
		return res;
	}
	public getFuelStarUrl(login:LoginService) {
		return config.baseUrl + '/fuelRefill/' + login.getSessionId() + '?userid=' + login.getUser().id + '&standalone=true';
	}
	public getFFBUrl(login:LoginService) {
		return config.baseUrl + '/fahrten_standalone.jsp?userid=' + login.getUser().id + '&sessionString=' + login.getSessionId();
	}
	public getDispolightExportUrl() {
		return config.restBaseUrl + '/dispolight/export/csv';
	}
	public getLogbookExportUrl() {
		return config.baseUrl + '/LogbookExportServlet';
	}
	public getLogbookLevel4ExportUrl() {
		return config.baseUrl + '/GpsDataServlet';
	}
	public getCanbusExportUrl(format:string) {
		return config.restBaseUrl + '/logbook/canbus/export/' + format;
	}
	public getLogbookFuelStartExportUrl() {
		return config.baseUrl + '/FuelStarExportServlet';
	}
	public getDriversExportUrl() {
		return config.restBaseUrl + '/driver/export';
	}
	public getToursExportUrl (format:string) {
		return config.restBaseUrl + '/tour/export/' + format;
	}
	public getAdminReportExportUrl (path:string) {
		return config.restBaseUrl + '/admin/report/' + path;
	}
	public getMaintenanceRecordsExportUrl (type:string) {
		return config.restBaseUrl + '/maintenance/export/' + type;
	}
	public getDriversPhotoExportUrl() {
		return config.restBaseUrl + '/driver/photo/export';
	}
	public getDriverCardsExportUrl() {
		return config.restBaseUrl + '/drivercard/export';
	}
	public getTaxiExportUrl() {
		return config.restBaseUrl + '/taxi/export';
	}
	// Creates a polyline from a list of info objects (from backend 'logbook/route')
	public createPolylineForRoute(route:any, bounds:google.maps.LatLngBounds) {
		if (!route.vehicleId)
			return null;
		let line = {
			id: 'route_v' + this.ProtoBuf.toString(route.vehicleId),
			visible: true,
			'static': true,
			draggable: false,
			geodesic: true,
			strokeWeight: 3,
			strokeColor: '#6060FB',
			path: [] as any[]
		};
		route.coordinatesFiltered.forEach((val:any) =>  {
			let latLng = new google.maps.LatLng(val.lat, val.lon);
			bounds.extend(latLng);
			line.path.push(latLng);
		});
		return line;
	}
	// Creates a polyline from a static route
	public createPolylineForStaticRoute(sRoute:any, bounds?:google.maps.LatLngBounds) {
		if(!sRoute.id)
			return null;
		var line = {
			id: 'sroute_v' + sRoute.id,
			routeId: sRoute.id,
			visible: true,
			'static': true,
			draggable: false,
			geodesic: true,
			strokeWidth: 2,
			strokeColor: sRoute.htmlColor || '#000000',
			path: [] as any[],
		};
		sRoute.coordinateList.split(';').forEach((coord:string) => {
			var latLngVal = coord.split(',');
			var latLng = new google.maps.LatLng(Number(latLngVal[0]), Number(latLngVal[1]));
			if(bounds)
				bounds.extend(latLng);
			line.path.push(latLng);
		});
		return line;
	}
	public createPolygonZone(zoneIdPrefix:string, id:any, polygon:any) {
		let zonePolygon = {
			id: zoneIdPrefix + id,
			paths: [[]] as [google.maps.LatLngLiteral[]],
			options: {
				fillColor: 'blue',
				strokeWeight: 1,
			},
			bounds: null as google.maps.LatLngBounds,
		}
		if(polygon && polygon.coordinates && polygon.coordinates.length)
			zonePolygon.paths = [polygon.coordinates[0].map((p:number[]) => ({lat: p[1], lng: p[0]}))];
		const bounds = new google.maps.LatLngBounds(); // We modify the map centre and zoom to contain all markers later
		zonePolygon.paths[0].forEach(p => bounds.extend(p));
		zonePolygon.bounds = bounds;
		return zonePolygon;
	}
	public createZonesList(filterZones:any[]) {
		let mapZones:any[] = [];
		for(const zoneId in filterZones) {
			const zone = filterZones[zoneId];
			if(!zone || !zone.name || ((!zone.latitude || !zone.longitude) && !zone.polygon))
				continue;
			let zoneObj;
			mapZones.push(zoneObj = {
				name: zone.name,
				center: { lat: zone.latitude, lng: zone.longitude },
				fillColor: ZONE_FILL_COLOUR, fillOpacity: 0.3,
				strokeColor: ZONE_STROKE_COLOUR, strokeOpacity: 0.5, strokeWeight: 3,
				fontSize: '35px', marginTop: '0.4em',
				radius: null as number,
				bounds: null as google.maps.LatLngBounds,
				polygon: null as any,
			});
			if(zone.labelPos === 'TOP')
				zoneObj.marginTop = '-6em';
			if(zone.radius) {
				zoneObj.radius = zone.radius;
				const north = google.maps.geometry.spherical.computeOffset(zoneObj.center, zone.radius, 0);
				const east = google.maps.geometry.spherical.computeOffset(zoneObj.center, zone.radius, 90);
				const south = google.maps.geometry.spherical.computeOffset(zoneObj.center, zone.radius, 180);
				const west = google.maps.geometry.spherical.computeOffset(zoneObj.center, zone.radius, 270);
				const bounds = new google.maps.LatLngBounds(south, north);
				bounds.extend(east);
				bounds.extend(west);
				zoneObj.bounds = bounds;
			}else if(zone.width && zone.height) { // A rectangle zone
				let center = new google.maps.LatLng(zone.latitude, zone.longitude);
				let ne = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(center, zone.width, 90), zone.height, 0);
				let sw = google.maps.geometry.spherical.computeOffset(google.maps.geometry.spherical.computeOffset(center, zone.width, 270), zone.height, 180);
				zoneObj.bounds = new google.maps.LatLngBounds(sw, ne);
			}else if(zone.polygon) {
				zoneObj.polygon = this.createPolygonZone('poly', zone.id, zone.polygon);
				zoneObj.bounds = zoneObj.polygon.bounds;
				const center = zoneObj.bounds.getCenter();
				zoneObj.center = { lat: center.lat(), lng: center.lng() };
			}
		}
		return mapZones;
	}
	public createZoneNameOverlay(zoneObj:any) {
		/** Need to declare this class here, as google.maps.OverlayView is not loaded yet at the file level */
		class ZoneMarkerOverlay extends google.maps.OverlayView {
			private text: string;
			private pos: google.maps.LatLng;
			private boundsSqm: number;
			private div?: HTMLElement;

			constructor(text: string, pos: google.maps.LatLng, bounds: google.maps.LatLngBounds) {
				super();
				this.text = text;
				this.pos = pos;
				this.boundsSqm = this.computeArea(bounds);
			}

			private computeArea(bounds: google.maps.LatLngBounds) {
				return google.maps.geometry.spherical.computeArea([
						bounds.getSouthWest(),
						new google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getNorthEast().lng()),
						bounds.getNorthEast(),
						new google.maps.LatLng(bounds.getNorthEast().lat(), bounds.getSouthWest().lng())
				]);
			}
			/** onAdd is called when the map's panes are ready and the overlay has been added to the map. */
			onAdd() {
				this.div = document.createElement("div");
				this.div.style.borderStyle = "none";
				this.div.style.borderWidth = "0px";
				this.div.style.position = "absolute";
				this.div.innerHTML = this.text;
				// Add the element to the "overlayLayer" pane as the overlay pane also contains the zones (polylines, circles etc)
				const panes = this.getPanes()!;
				panes.overlayLayer.appendChild(this.div);
			}

			onRemove() {
				if(!this.div)
					return;
				this.div.parentNode.removeChild(this.div);
				delete this.div;
			}

			draw() {
				if(!this.div)
					return;
				// The projection of the overlay is needed to calculate px from LatLng
				const proj = this.getProjection();
				// Check whether to show or hide the label
				const projBounds = proj.getVisibleRegion().latLngBounds;
				const projAreaSqm = this.computeArea(projBounds);
				if(this.boundsSqm < projAreaSqm / 50) { // We figured the threshold value out visually using trial & error
					this.div.style.display = 'none';
					return;
				}
				// Okay position the label correctly
				this.div.style.display = 'initial';
				this.div.style.color = ZONE_STROKE_COLOUR;
				const posPx = proj.fromLatLngToDivPixel(this.pos);
				this.div.style.left = Math.round(posPx.x - (this.div.clientWidth / 2.0)) + 'px';
				this.div.style.top = Math.round(posPx.y - (this.div.clientHeight / 2.0)) + 'px';
			}
		}
		return new ZoneMarkerOverlay(zoneObj.name, new google.maps.LatLng(zoneObj.center.lat, zoneObj.center.lng), zoneObj.bounds);
	}
	/** Create a list of objects with title, propName properties for aux columns from dispolight / logbook infos */
	public createAuxColumns(infos:any[]) {
		// Set-up the aux columns
		var auxColumns:any[] = [];
		if(!infos.length)
			return auxColumns;
		infos.forEach((info:any) => {
			for(var i = 1; i <= 15; i++) {
				if(auxColumns[i])
					continue;
				var translation = info['aux' + i + 'StatusTranslation'];
				if((translation && translation !== '') || (info['iconAux' + i] && info['iconAux' + i] !== '')) {
//						var propName = info.hasOwnProperty('durationAux1') ? 'durationAux' + i : 'binaryStatus' + i;
					auxColumns[i] = {
						title: translation,
						propName: 'durationAux' + i,
						enabledPropName: 'DurationAux' + i,
						propNameIcon: 'iconAux' + i,
						enabledPropNameIcon: 'IconAux' + i,
					};
				}
			}
		});
		let res:any[] = [];
		auxColumns.forEach((el) => { res.push(el); }); // Remove 'holes' in the array, causes errors with ngRepeat
		return res;
	}
	/** http://stackoverflow.com/a/4092928 and http://stackoverflow.com/a/12026134
	 * Calculates the bounds this map would display at a given zoom level.
	 *
	 * @method boundsAt
	 * @param {google.maps.Map}          ma             The map to work on
	 * @param {Number}                   zoom           Zoom level to use for calculation.
	 * @param {google.maps.LatLng}       [centerLatLng] May be set to specify a different center than the current map center.
	 * @param {Element}                  [div]          May be set to specify a different map viewport than this.getDiv() (only used to get dimensions).
	 * @return {google.maps.LatLngBounds} the calculated bounds.
	 *
	 * @example
	 * var bounds = map.boundsAt(map, 5); // same as map.boundsAt(map, 5, map.getCenter(), map.getDiv());
	 */
	public mapBoundsAt(map:google.maps.Map, zoom:number, centerLatLng:google.maps.LatLng, mapDiv?:HTMLElement) {
		var p = map.getProjection();
		if (!p) return undefined;
		var zf = Math.pow(2, zoom) * 2;
		var d = $(mapDiv || map.getDiv());
		var dw = d.width() / zf;
		var dh = d.height() / zf;
		var cpx = p.fromLatLngToPoint(centerLatLng || map.getCenter());
		return new google.maps.LatLngBounds(
			p.fromPointToLatLng(new google.maps.Point(cpx.x - dw, cpx.y + dh)),
			p.fromPointToLatLng(new google.maps.Point(cpx.x + dw, cpx.y - dh)));
	}
	/** See CoordinateCalculations.java#convertAngularMinutesInDegrees */
	public angularMinLatToDeg(latitudeMinutes:number) {
		const ytmp = latitudeMinutes;
		const yminutes = ytmp % 100.0;
		let ydegree;
		if(ytmp >= 0) {
			ydegree = Math.floor(ytmp / 100);
		} else {
			ydegree = Math.ceil(ytmp / 100);
		}
		return ydegree + (yminutes / 60.0);
	}
	/** See CoordinateCalculations.java#convertAngularMinutesInDegrees */
	public angularMinLonToDeg(longitudeMinutes:number) {
		const xtmp = longitudeMinutes;
		const xminutes = xtmp % 100;
		let xdegree;
		if(xtmp >= 0) {
			xdegree = Math.floor(xtmp / 100);
		} else {
			xdegree = Math.ceil(xtmp / 100);
		}
		return xdegree + (xminutes/60);
	}
	/** Filter the points a little by discarding uselessly close points **/
	public filterCoordinates(coordinates:any[], minDistM:number, latProp?:string, lonProp?:string) {
		latProp = latProp || 'lat'; lonProp = lonProp || 'lon'; minDistM = minDistM || 5 /* meter */;
		let prevLatLng:google.maps.LatLng;
		return coordinates.filter((curr, index) => {
			var latLng = new google.maps.LatLng(curr[latProp], curr[lonProp]);
			if(index === (coordinates.length - 1))
				return true; // always keep the last point
			var res = true;
			// TODO Maybe don't filter, as otherwise start/stop icons might be off track, or check for equality with these start/stop markers
			if(prevLatLng && google.maps.geometry.spherical.computeDistanceBetween(prevLatLng, latLng) < minDistM)
				res = false;
			prevLatLng = latLng;
			return res;
		});
	}
	public createMarkerObj(imgBase:string, id:string, info:any, latLng:google.maps.LatLng, icon?:string, width?:number, height?:number) {
		return {
			id: id, /* An id attribute is required by angularjs maps */
			info: info,
			imgBase: imgBase,
			coords: latLng,
			showWindow: false,
			icon: icon ? {
				url: icon,
				size: new window.google.maps.Size(width, height),
				origin: new window.google.maps.Point(0,0),
				anchor: new window.google.maps.Point(width / 2, height / 2)
			} : null,
			options: {
				draggable: false,
			}
		};
	}
	public createMarkersForTour(createMarkerObjFunc:any, imgBase:string, startData:any, stopData:any, iconWidth:number, iconHeight:number, bounds:any) {
		var markers = [];

		var latLngStart = new google.maps.LatLng(startData.latitude, startData.longitude);
		bounds.extend(latLngStart);
		var markerStart = createMarkerObjFunc(imgBase, 'A', null, startData, latLngStart, this.startIconUrl(imgBase, 'A', false, 10), iconWidth, iconHeight);
		markers.push(markerStart);

		var latLngStop = new google.maps.LatLng(stopData.latitude, stopData.longitude);
		bounds.extend(latLngStop);
		var markerStop = createMarkerObjFunc(imgBase, 'B', null, stopData, latLngStop, this.stopIconUrl(imgBase, 'B', false, 10), iconWidth, iconHeight);
		markers.push(markerStop);

		return markers;
	}
	public createMarkersForRoute(routeIdFunc:any, createMarkerObjFunc:any, imgBase:string, route:any, useStart:boolean, useStop:boolean, useFirstAndLast:boolean, useArrows:boolean, iconWidth:number, iconHeight:number, bounds:any, idToMarker:any, coords:any[]) {
		var markers:any[] = [];
		let addStartStopMarker = (i:number, startStopPoint:any, startOrStop:any) => {
			var id = routeIdFunc(route.vehicleId, startStopPoint.coordinate.lat, startStopPoint.coordinate.lon, startOrStop);
			startStopPoint.startOrStop = startOrStop; // We need the nr and the bool for the map info popup
			startStopPoint.startOrStopNr = i;
			var latLng = new google.maps.LatLng(startStopPoint.coordinate.lat, startStopPoint.coordinate.lon);
			bounds.extend(latLng);
			var useLightColour = startStopPoint.pauseTooShort ? true : false;
			var fontSize = (i <= 9) ? 10 : 8;
			var iconUrl = startOrStop === 'start' ?
					this.startIconUrl(imgBase, i.toString(), useLightColour, fontSize) :
					this.stopIconUrl(imgBase, i.toString(), useLightColour, fontSize);
			var marker = createMarkerObjFunc(imgBase, id, startStopPoint, latLng, iconUrl, iconWidth, iconHeight);
			markers.push(marker);
			idToMarker[id] = marker;
			coords.push([latLng.lat(), latLng.lng(), id, startOrStop]);
		}
		let addFirstLastMarker = (point:any, firstOrLast:any) => {
			var id = routeIdFunc(route.vehicleId, point.coordinate.lat, point.coordinate.lon, firstOrLast);
			point.startOrStop = firstOrLast; // We need the nr and the bool for the map info popup
			point.startOrStopNr = 0;
			point.lat = point.coordinate.lat;
			point.lon = point.coordinate.lon;
			point.vehicleId = route.vehicleId;
			point.vehicleName = route.vehicleName;
			point.icon = { url: route.vehicleIcon };
			var latLng = new google.maps.LatLng(point.coordinate.lat, point.coordinate.lon);
			bounds.extend(latLng);
			var iconUrl = firstOrLast === 'first' ?
					this.firstIconUrl(imgBase, ' ', 4) : // Just a space is actually three character, which makes the icon larger, so we reduce the font size (usually 10)
					this.lastIconUrl(imgBase, ' ', 4);
			var marker = createMarkerObjFunc(imgBase, id, point, latLng, iconUrl, iconWidth, iconHeight);
			markers.push(marker);
			idToMarker[id] = marker;
			coords.push([latLng.lat(), latLng.lng(), id, firstOrLast]);
		}
		var i = 1;
		if(useStart) {
			(route.startPoints || []).forEach((sP:any) => {
				addStartStopMarker(i++, sP, 'start');
			});
		}
		if(useStop) {
			i = 1;
			(route.stopPoints || []).forEach((sP:any) => {
				addStartStopMarker(i++, sP, 'stop');
			})
		}
		if(useFirstAndLast && route.coordinatesFiltered.length >= 2) {
			route.first = { coordinate: route.coordinatesFiltered[0] };
			route.last = { coordinate: route.coordinatesFiltered[route.coordinatesFiltered.length - 1] };
			addFirstLastMarker(route.first, 'first');
			addFirstLastMarker(route.last, 'last');
		}
		let toRadians = (angdeg:number) => {
			return angdeg / 180.0 * Math.PI;
		}
		let toDegrees = (angrad:number) => {
			return angrad * 180.0 / Math.PI;
		}
		let rotInDegree = (curr:any, next:any) => {
			var lat1 = toRadians(curr.lat);
			var lat2 = toRadians(next.lat);
			var dlon = toRadians(next.lon - curr.lon);
			var y = Math.sin(dlon) * Math.cos(lat2);
			var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dlon);
			return (toDegrees(Math.atan2(y, x)) + 360) % 360;
		}
		if(useArrows) {
			var prevLatLng:any;
			const minDist = route.coordinatesFiltered.length > 100 ? 1500 : 200;
			var coordsFilteredMore = route.coordinatesFiltered.filter((coord:any) => {
				var latLng = new google.maps.LatLng(coord.lat, coord.lon);
				if(prevLatLng && google.maps.geometry.spherical.computeDistanceBetween (prevLatLng, latLng) < minDist)
					return false; // Filter the points a little by discarding uselessly close points
				prevLatLng = latLng;
				return true;
			});
			coordsFilteredMore.forEach((coord:any, i:number) => {
				if(i === coordsFilteredMore.length - 1)
					return; // We need to look at the next coordinate
				var latLng = new google.maps.LatLng(coord.lat, coord.lon);
				var id = 'ras' + this.ProtoBuf.toString(route.vehicleId) + coord.lat + coord.lon;
				var marker = createMarkerObjFunc(imgBase, id, coord, latLng, 'TOBEREMOVED', iconWidth, iconHeight);
				marker.icon = { // Replace TOBEREMOVED
					path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
//						origin: // has no effect on path as it seems
					scale: 2,
					strokeColor: '#3cf500', fillColor: '#3cf500',
					rotation: rotInDegree(coord, coordsFilteredMore[i+1]),
				};
				markers.push(marker);
				idToMarker[id] = marker;
				coords.push([latLng.lat(), latLng.lng(), id, 'arrow']);
			});
		}
		return markers;
	}
	/** Appends new marker objects to 'markers' and returns the bounds of the searchBox places results */
	public createMapPlacesMarkers(searchBox:google.maps.places.SearchBox, markers:any[]) {
		let places = searchBox.getPlaces();
		if (places.length === 0)
			return null;
		var bounds = new google.maps.LatLngBounds();
		places.forEach(place => { // For each place, get the icon, name and location.
			// Create a marker for each place.
			markers.push({
				id: 'searchMarker' + this.randomString(), /* An id attribute is required by angularjs maps, needs to be different when replacing marker objects */
				info: {
					address: place.formatted_address,
					latitude: place.geometry.location.lat(),
					longitude: place.geometry.location.lng(),
				},
				title: place.name,
				coords: place.geometry.location,
				icon: {
					url: place.icon,
					size: new google.maps.Size(71, 71),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(17, 34),
					scaledSize: new google.maps.Size(25, 25)
				},
				draggable: false,
			});
			if (place.geometry.viewport) // Only geocodes have viewport.
				bounds.union(place.geometry.viewport);
			else
				bounds.extend(place.geometry.location);
		});
		return bounds;
	}
	public iconUrl(imgBase:string, text:string, fontSize:number, iconColour:string, fontColour:string) {
		return imgBase + 'cgi-bin/textcircle.cgi?text=' + text + '&bgcolor=' + iconColour + '&fontcolor=' + fontColour + '&fontsize=' +
				fontSize + '&borderwidth=0&bordercolor=' + iconColour;
	}
	public startIconUrl(imgBase:string, text:string, useLightColour:boolean, fontSize:number) {
		var GREEN = '00ff00';
		var LIGHT_GREEN = 'b8ffb8';
		var iconColour = useLightColour ? LIGHT_GREEN : GREEN;
		var fontColour = '000000';
		return this.iconUrl(imgBase, text, fontSize, iconColour, fontColour);
	}
	public stopIconUrl(imgBase:string, text:string, useLightColor:boolean, fontSize:number) {
		var RED = 'ff0000';
		var LIGHT_RED = 'ffacac';
		var iconColour = useLightColor ? LIGHT_RED : RED;
		var fontColour = '000000';
		return this.iconUrl(imgBase, text, fontSize, iconColour, fontColour);
	}
	public firstIconUrl(imgBase:string, text:string, fontSize:number) {
		var GREEN = '77d977';
		var fontColour = '000000';
		return this.iconUrl(imgBase, text, fontSize, GREEN, fontColour);
	}
	public lastIconUrl(imgBase:string, text:string, fontSize:number) {
		var RED = 'd97777';
		var fontColour = '000000';
		return this.iconUrl(imgBase, text, fontSize, RED, fontColour);
	}
	public getFoundationInitObj() {
		return this.foundationInitObj;
	}
	private handleWindowResized() {
		if(!rendersInBrowser())
			return;
		var $viewContainer = $('.view-container');
		// if(this.FullHeightPage.fullHeight)
		// 	$viewContainer.addClass('full-height');
		this.isScreenSmall = window.matchMedia((window as any).Foundation.media_queries.small).matches;
		this.isScreenMedium = window.matchMedia((window as any).Foundation.media_queries.medium).matches;
		this.isScreenLarge = window.matchMedia((window as any).Foundation.media_queries.large).matches;
		this.isScreenXLarge = window.matchMedia((window as any).Foundation.media_queries.xlarge).matches;
		this.isScreenXXLarge = window.matchMedia((window as any).Foundation.media_queries.xxlarge).matches;
		this.screenWidth = $(window).width();
		this.screenHeight = $(window).height();
		if(this.isScreenXXLarge)
			this.screenSize = 'xxlarge';
		else if(this.isScreenXLarge)
			this.screenSize = 'xlarge';
		else if(this.isScreenLarge)
			this.screenSize = 'large';
		else if(this.isScreenMedium)
			this.screenSize = 'medium';
		else if(this.isScreenSmall)
			this.screenSize = 'small';
		this.screen$.emit({
			isScreenSmall: this.isScreenSmall,
			isScreenMedium: this.isScreenMedium,
			isScreenLarge: this.isScreenLarge,
			isScreenXLarge: this.isScreenXLarge,
			isScreenXXLarge: this.isScreenXXLarge,
			screenSize: this.screenSize,
			screenWidth: this.screenWidth,
			screenHeight: this.screenHeight,
		});
	}
	private doViewContentLoaded() {
		if(!rendersInBrowser())
			return;
		this.ngZone.runOutsideAngular(() => { // Running inside angular would cause mouseout event listeners to be installed by zone.js and processed by angular as change detection, which cause constant high CPU usage dues to change detection being triggered
			($(document) as any).foundation(this.foundationInitObj);
			// XXX HACK to work around foundation.reveal.js not acting on the re-init above for data-reveal-id links
			(window as any).Foundation.libs.reveal.events($(document));
			// Event listener to when reveal popups are opnened
			($(document) as any).on('opened.fndtn.reveal', '[data-reveal]', (e: any) => {
				const dialogId = e?.target?.id;
				if(!dialogId)
					return;
				this.dialogOpened$.emit(dialogId);
			});
		})
		// XXX HACK to work around https://github.com/zurb/foundation/issues/4177
		this.jQuery_extend(true /* deep */, (window as any).Foundation.libs.abide.settings, this.foundationInitObj.abide);
		$(window).off('.infostars-tools').on('resize.infostars-tools', () => {
			this.handleWindowResized();
			// XXX is something like this still needed in angular.io?
			// if(!this.rootScope.$$phase)
			// 	this.rootScope.$digest();
		});

		setTimeout(() => {
			// XXX HACK to make sure we always display the menu large enough
			// Needs to run *after* showNav has potentially hidden the menu
			this.handleWindowResized();
		});
	}
	public defaultViewContentLoadedFunc() {
		this.doViewContentLoaded();
	}
	/** Use (one-time) a custom function for the current controller to delay or customise the viewContentLoaded behaviour
	 *  This can be useful to delay foundation js initialisation if the view is dynamic. */
	public setUseCustomViewContentLoadedFunc(useCustom:boolean) {
		this.useCustomViewContentLoadedFunc = useCustom;
	}
}
